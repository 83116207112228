export default function H2({
  className = "",
  color = "text-dark-blue",
  children,
}) {
  return (
    <h2 className={`text-section-title font-medium ${color} ${className}`}>
      {children}
    </h2>
  );
}
