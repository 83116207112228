"use client";

import H2 from "@/components/H2";
import Link from "next/link";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <html>
      <body>
        <div className="pt-50 grid place-content-center min-h-[50dvh] text-center">
          <H2>There was an error loading this content.</H2>
          <p>Please try again shortly.</p>
          <Link href="/">Return Home</Link>
          <button onClick={() => reset()}>Try again</button>
        </div>
      </body>
    </html>
  );
}
